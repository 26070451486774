<div class="row">
  <div class="col-md-12">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      id="summaryTable"
      class="table table-sm table-striped table-bordered overflow-x">
      <thead>
        <tr class="text-truncate align-middle">
          <th rowspan="2"></th>
          <th rowspan="2">vessel Name</th>
          <th rowspan="2">CHAR.</th>
          <th rowspan="2">Position</th>
          <th rowspan="2">B-L</th>
          <th rowspan="2">BL</th>
          <th rowspan="2">Cargo</th>
          <th rowspan="2">DEST.</th>
          <th rowspan="2">ETA</th>
          <th colspan="2">Speed</th>
          <th rowspan="2">Wind/Sea</th>
          <th colspan="2">Dry Dock</th>
          <th rowspan="2">GRT</th>
          <th rowspan="2">FLG</th>
          <th rowspan="2">P&I</th>
          <th rowspan="2">MGR</th>
          <th rowspan="2">Owner</th>
          <th rowspan="2">DD</th>
          <th rowspan="2">Age</th>
          <th rowspan="2">Builder</th>
        </tr>
        <tr class="text-truncate align-middle">
          <th>C/P</th>
          <th>Present</th>
          <th>Last</th>
          <th>Next</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of reports" [ngClass]="{'opacity-50': checkUpdatedNoon(report[1])}" class="text-truncate">
          <td>
              <a (click)="downloadFile(report[1])" type="submit" *ngIf="report[1].fileId">
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
              </a>
          </td>
          <td [ngClass]="{'text-green': report[1].voyageDetails.isLaden}">{{capitalize(report[0].name.name)}}</td>
          <td>{{organisationFormatter(report[2].charterer)}}</td>
          <td>{{capitalize(report[1].position.currentLocation).substring(0,20)}}</td>
          <td [ngClass]="{'text-green': report[1].voyageDetails.isLaden}">{{report[1].voyageDetails.isLaden ? "Laden" : "Ballast"}}</td>
          <td [ngClass]="{'text-green': report[1].voyageDetails.isLaden}">{{blFormatter(report[2], report[1].voyageDetails.isLaden)}}</td>
          <td [ngClass]="{'text-green': report[1].voyageDetails.isLaden}">{{formatCargoOnboard(report[1])}}</td>
          <td>{{capitalize(report[1].voyageDetails.destination)}}</td>
          <td [ngClass]="{'text-green': checkEta(report[1])}">{{formatDate(report[1].voyageDetails.eta)}}</td>
          <td>{{report[0].spec?.charterPartySpeed?.economicalLadenSpeed}}-{{report[0].spec?.charterPartySpeed?.maximumBallastSpeed}}</td>
          <td>{{speedFormatter(report[1].speed.averageSpeed)}}</td>
          <td>{{windSeaFormatter(report[1])}}</td>
          <td>{{report[0].spec?.dryDock?.lastDryDock}}</td>
          <td [ngClass] ="{'text-red': checkDryDockExpirey(report[0]) === 0, 'text-warning': checkDryDockExpirey(report[0]) === 1 }">{{report[0].spec?.dryDock?.nextDryDock}}</td>
          <td>{{report[0].spec.dimensionsCapacities.GRT | number}}</td>
          <td>{{report[0].name.flag}}</td>
          <td>{{organisationFormatter(report[0].spec.ownershipOperationProtection.protectionIndemnityId)}}</td>
          <td>{{organisationFormatter(report[0].spec.ownershipOperationProtection.managerId)}}</td>
          <td>{{organisationFormatter(report[0].spec.ownershipOperationProtection.ownerId)}}</td>
          <td>{{report[0].spec.yearBuilt}}</td>
          <td>{{vesselAge(report[0])}}</td>
          <td>{{organisationFormatter(report[0].spec.ownershipOperationProtection.builderId)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-12">
    <table datatable [dtOptions]="bunkerDtOptions" [dtTrigger]="bunkerDtTrigger"
      id="bunkerTable"
      class="table table-sm table-striped table-bordered">
      <thead>
        <tr class="align-middle">
          <th rowspan="2">vessel Name</th>
          <th rowspan="2">IMO</th>
          <th rowspan="2">CHAR.</th>
          <th rowspan="2">OPS</th>
          <th rowspan="2">Position</th>
          <th rowspan="2">B/L</th>
          <th colspan="2">ROB</th>
          <th colspan="2">Last Received Date</th>
          <th colspan="2">Last Received QTY</th>
          <th rowspan="2">Price</th>
          <th rowspan="2">ETA</th>
          <th rowspan="2">DEST.</th>
        </tr>
        <tr>
          <th>LSFO</th>
          <th>LSMGO</th>
          <th>LSFO</th>
          <th>LSMGO</th>
          <th>LSFO</th>
          <th>LSMGO</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of reports" [ngClass]="{'opacity-50': checkUpdatedNoon(report[1])}">
          <td>{{report[0].name.name}}</td>
          <td>{{report[0].IMO}}</td>
          <td>{{organisationFormatter(report[2].charterer)}}</td>
          <td></td>
          <td>{{capitalize(report[1].position.currentLocation).substring(0,20)}}</td>
          <td>{{report[1].voyageDetails.isLaden ? "Laden" : "Ballast"}}</td>
          <td>{{robFormatter(report[1], 'fo')}}</td>
          <td>{{robFormatter(report[1], 'lsmgo')}}</td>
          <td>{{formatDate(bunkerLastRecievedDate(report[1], 'lsfo'))}}</td>
          <td>{{formatDate(bunkerLastRecievedDate(report[1], 'lsmgo'))}}</td>
          <td>{{bunkerLastRecievedFormatter(report[1], 'fo')}}</td>
          <td>{{bunkerLastRecievedFormatter(report[1], 'lsmgo')}}</td>
          <td></td>
          <td>{{formatDate(report[1].voyageDetails.eta)}}</td>
          <td>{{capitalize(report[1].voyageDetails.destination)}}</td>
      </tbody>
    </table>
  </div>
</div>

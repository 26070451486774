import { Component, OnInit } from "@angular/core";
import { DateRange } from "src/app/shared/components";
import { PushNotificationService } from "src/app/shared/services/push-notification.service";
import { Router } from "@angular/router";
import { MarketService } from "../market.service";
import moment from "moment";

@Component({
  selector: "app-market-toolbar",
  templateUrl: "./market-toolbar.component.html",
})
export class MarketToolbarComponent implements OnInit {
  selectedProvider: string;
  providers: string[];
  dateRange: DateRange;

  constructor(
    private _router: Router,
    private _marketService: MarketService,
    private _pushNotificationService: PushNotificationService
  ) {}
  async ngOnInit() {
    this.selectedProvider = `Platts`;
    this.dateRange = {
      startDate: moment().add(-1, "year").startOf("year").toDate(),
      endDate: moment().endOf("month").toDate(),
    };
  }

  marketFiles() {
    this._router
      .navigate([
        `pricing/files`,
        this.selectedProvider,
        this.dateRange.startDate.getTime(),
        this.dateRange.endDate.getTime(),
      ])
      .then((_) => _);
  }

  pricingList() {
    this._router
      .navigate([
        `pricing/${this.selectedProvider}`,
        this.dateRange.startDate.getTime(),
        this.dateRange.endDate.getTime(),
      ])
      .then();
  }

  subscribe() {
    return this._pushNotificationService.registerPushNotifications();
  }

  pricingPlotAll() {
    this._router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this._router
        .navigate([
          `pricing/report`,
          this.dateRange.startDate.getTime(),
          this.dateRange.endDate.getTime(),
        ])
        .then((e) => console.log(`Result of Navigation is ${e}`));
    });
  }
}

<div class="row">
  <div class="col-md-12">
    <div id="mapid" style="height: 70vh; margin-bottom: 10px"> </div>
  </div>
</div>

<div class="row">
  <div class="col-md-4 col-xs-12">
    <div class="card shadow-sm" *appHideIfNotInRole="['vessels-viewer']">
      <div class="card-header">Last voyages</div>
      <div class="card-body">
        <div class="table table-sm-responsive">
          <table datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="table table-sm no-margin">
            <thead>
              <tr>
                <th class="text-center">Vessel</th>
                <th class="text-center">Ship Voyage No.</th>
                <th class="text-center">Agent Voyage No.</th>
                <th class="text-center">Days</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let v of ongoingVoyages">
                <td class="text-center"><a
                    [routerLink]="['/vessel/', v.vesselIMO]">{{v.vesselName}}</a></td>
                <td class="text-center"><a
                    [routerLink]="['/vessels/voyage', v.id]">{{v.shipVoyageNo}}
                  </a></td>
                <td class="text-center"><a
                    [routerLink]="['/vessels/voyage', v.id]">{{v.agentVoyageNo}}
                  </a></td>
                <td class="text-right">{{calculateDays(v)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-8 col-xs-12">
    <div class="card shadow-sm ">
      <div class="card-header">Arrivals & Departures</div>
      <div class="card-body">
        <div class="table table-sm-responsive">
          <table datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="table table-sm no-margin">
            <thead>
              <tr>
                <th class="text-center">Vessel</th>
                <th class="text-center">Voyage</th>
                <th class="text-center">Type</th>
                <th class="text-center">Date(UTC)</th>
                <th class="text-center">Source</th>
                <th class="text-center">Destination</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let v of arrivalDepartures">
                <td class="text-center">{{v.vesselName}}</td>
                <td class="text-center">{{v.voyageDetails.voyageNumber}}</td>
                <td class="text-center">{{v.reportType}}</td>
                <td class="text-center">{{calculateUTCTime(v.date.timestamp)}}</td>
                <td class="text-center">{{v.voyageDetails.source}}</td>
                <td class="text-center">{{v.voyageDetails.destination}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

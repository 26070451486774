<div class="modal modal-fullscreen modal-lg" tabindex="-1" role="dialog" id="filePreviewModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ filePreviewTitle }}</h5>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeFilePreview()">Close</button>
      </div>
      <div class="modal-body">
        <app-pdf-viewer [fileId]="previewFileId" />
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { MarketService } from './market.service';
import { MarketFilesComponent } from './market-files/market-files.component';
import { AuthenticationModule } from '../authentication/authentication.module';
import { MarketListComponent } from './market-list/market-list.component';
import { MarketReportComponent } from './market-report/market-report.component';
import { ImportPublicationComponent } from './market-list/import-publication/import-publication.component';
import { MarketToolbarComponent } from './market-toolbar/market-toolbar.component';

@NgModule({
  imports: [SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    CommonModule,
    AuthenticationModule],
  declarations: [
    MarketReportComponent,
    MarketListComponent,
    ImportPublicationComponent,
    MarketFilesComponent,
    MarketToolbarComponent,
  ],
  providers: [MarketService]
})

export class MarketModule { }

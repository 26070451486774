import { PdfViewerPopUpComponent } from './components/pdf-viewer-pop-up/pdf-viewer-pop-up.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HeaderBarComponent } from './navbar';
import { DateRangeComponent, DateRangeComponentValidatorDirective } from './components';
import { PersianDatePipe } from './components';
import { PersianNumberPipe } from './components';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PersianDateOnlyPipe } from 'src/app/shared/components/persian-date-only.pipe';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';

import {
  DateTimeZoneComponent,
  DateTimeZoneComponentValidatorDirective,
} from 'src/app/shared/components/date-time-zone/date-time-zone.component';
import { TitleToolbarDirective } from './components/title-bar/title-toolbar.directive';
import { PgDatePipe } from './pipes/pg-date.pipe';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { ImageViewerComponent } from './components/img-viewer/img-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileUploadComponent } from './components/file-uploader/file-uploader.component';
import { OrganisationSelectorComponent } from './components/autocomplete/organisationselector.component';
import { PushNotificationService } from './services/push-notification.service';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { AuthenticationModule } from '../authentication/authentication.module';
import { NotificationToolbarComponent } from './components/notification-toolbar/notification-toolbar.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';
import { AutoCompleteComponent } from './components/autocomplete/auto-complete.component';
import { GeoCoordinateDmsPipe } from './pipes/geo-coordinate-dms.pipe';
import { SideBarComponent } from './navbar/side-bar/side-bar.component';
import { ChunkFileUploadComponent } from './components/file-uploader/chunk-file-upload.component';
import { MessagesComponent } from './components/messages/messages.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';


/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */
@NgModule({ declarations: [HeaderBarComponent, DateRangeComponent,
        PersianDatePipe, PersianDateOnlyPipe,
        PersianNumberPipe, LoaderComponent,
        DateRangeComponentValidatorDirective,
        DateTimeZoneComponent,
        DateTimeZoneComponentValidatorDirective,
        TitleToolbarDirective,
        PgDatePipe,
        GeoCoordinateDmsPipe,
        PdfViewerComponent,
        PdfViewerPopUpComponent,
        ImageViewerComponent,
        FileUploadComponent,
        ChunkFileUploadComponent,
        OrganisationSelectorComponent,
        TitleBarComponent,
        NotificationToolbarComponent,
        SlideshowComponent,
        AutoCompleteComponent,
        SideBarComponent,
        MessagesComponent,
        TimelineComponent,
        VideoPlayerComponent
    ],
    exports: [HeaderBarComponent,
        DateRangeComponent,
        CommonModule,
        FormsModule,
        PersianDatePipe,
        PersianDateOnlyPipe,
        PersianNumberPipe,
        PgDatePipe,
        GeoCoordinateDmsPipe,
        RouterModule,
        LoaderComponent,
        DateRangeComponentValidatorDirective,
        DateTimeZoneComponent,
        DateTimeZoneComponentValidatorDirective,
        PdfViewerComponent,
        PdfViewerPopUpComponent,
        ImageViewerComponent,
        FileUploadComponent,
        ChunkFileUploadComponent,
        TitleToolbarDirective,
        OrganisationSelectorComponent,
        TitleBarComponent,
        SlideshowComponent,
        AutoCompleteComponent,
        SideBarComponent,
        MessagesComponent,
        TimelineComponent,
        VideoPlayerComponent
    ], imports: [CommonModule,
        AuthenticationModule,
        RouterModule,
        NgxSpinnerModule,
        PdfViewerModule,
        ReactiveFormsModule,
        FormsModule], providers: [DataTablesService,
        PushNotificationService, provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule { }

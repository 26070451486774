import { Component, EventEmitter, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";
import {
  FederalReserveBankData,
  PriceIndex,
  MarketService,
} from "src/app/market/market.service";
import { ActivatedRoute } from "@angular/router";
import { DateRange } from "src/app/shared/components";
import { DateTimeHelpers } from "src/app/shared/helpers/date-time.helpers";
import * as _ from "lodash";
import { first } from "rxjs/operators";
import * as moment from "moment";
import { KeycloakService } from "src/app/authentication/keycloak.service";
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum,
  PgTitleComponent,
  PgToolbarComponent,
} from "../../shared/components/title-bar/title-bar.component";
import { MarketToolbarComponent } from "../market-toolbar/market-toolbar.component";

interface IndexDiff {
  propaneDiff: number;
  propanePercentage: number;
  butaneDiff: number;
  butanePercentage: number;
  fiftyFiftyMix: number;
  thirtySeventyMix: number;
}

@Component({
  selector: "app-market-report",
  templateUrl: "./market-report.component.html",
  styleUrls: ["./market-report.component.css"],
})
export class MarketReportComponent
  implements
    OnInit,
    PgTitleComponent,
    PgDateRangeTitleComponent,
    PgToolbarComponent
{
  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = MarketToolbarComponent;
  toolbarComponent: MarketToolbarComponent;
  dateRange: DateRange;
  indexChanges: any[];
  indexForCompare: any[];
  firstIndex: any;
  secondIndex: any;
  diff: IndexDiff = {
    propaneDiff: null,
    propanePercentage: null,
    butaneDiff: null,
    butanePercentage: null,
    fiftyFiftyMix: null,
    thirtySeventyMix: null,
  };

  federalReserveData: FederalReserveBankData[];

  dateArray = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _marketService: MarketService,
    private _keycloakService: KeycloakService
  ) {}

  async ngOnInit() {
    const params = await this._activatedRoute.paramMap
      .pipe(first())
      .toPromise();
    if (params.get("startDate") && params.get("endDate")) {
      this.dateRange = {
        startDate: new Date(+params.get("startDate")),
        endDate: new Date(+params.get("endDate")),
      };
    } else {
      this.dateRange = {
        startDate: moment().add(-1, "year").startOf("year").toDate(),
        endDate: moment().endOf("month").toDate(),
      };
    }
    this.dateArray = this.setDateArray(
      this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()
    );
    this.title.emit("Market");
    this.dateRangeSubTitle.emit([
      this.dateRange,
      PgDateRangeTitleEnum.defaultTitle,
    ]);
    const prices = (
      await this._marketService.getAll(
        this.dateRange.startDate.getTime(),
        this.dateRange.endDate.getTime()
      )
    ).sort((a, b) => a.timestamp - b.timestamp);
    const federalReserveData = await this._marketService.getFederalReserveData(
      this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()
    );
    const data = this.groupSOFRData(federalReserveData);
    this.plotFederalReserveData(
      data,
      "federalReserveDiv",
      this.findSofrMin(federalReserveData),
      this.findSofrMax(federalReserveData)
    );
    const groupedByProduct = this.groupByProduct(prices);
    const lpgMax = Math.max(
      this.findMax(prices, "Butane"),
      this.findMax(prices, "Propane")
    );
    const blpg1Max = this.findMax(prices, "BLPG1");
    const oilMax = this.findMax(prices, "Crude Oil");
    const wetMax = this.findMax(prices, "Wet");
    const bunkerMax = Math.max(
      this.findMax(prices, "HFO"),
      this.findMax(prices, "MGO"),
      this.findMax(prices, "VLSFO")
    );

    this.plotPerProduct(
      groupedByProduct.find((p) => p.productId === "Butane"),
      "butaneReportDiv",
      0,
      lpgMax
    );
    this.plotPerProduct(
      groupedByProduct.find((p) => p.productId === "Propane"),
      "propaneReportDiv",
      0,
      lpgMax
    );
    if (groupedByProduct.find((p) => p.productId === "BLPG1")) {
      this.plotPerProduct(
        groupedByProduct.find((p) => p.productId === "BLPG1"),
        "balticReportDiv",
        0,
        blpg1Max
      );
    }

    if (groupedByProduct.find((p) => p.productId === "Crude Oil")) {
      this.plotPerProduct(
        groupedByProduct.find((p) => p.productId === "Crude Oil"),
        "crudOilReportDiv",
        0,
        oilMax
      );
    }

    if (groupedByProduct.find((p) => p.productId === "Wet")) {
      this.plotPerProduct(
        groupedByProduct.find((p) => p.productId === "Wet"),
        "demolitionReportDiv",
        0,
        wetMax
      );
    }

    const bunkerTypes = ["HFO", "MGO", "VLSFO"];
    const bunkerIndexSeries = groupedByProduct
      .filter((p) => bunkerTypes.some((t) => t === p.productId))
      .map((p) => ({
        productId: p.productId,
        series: p.series.filter((s) => s.name === "Fujairah"),
      }));
    this.plotBunkerPrices(bunkerIndexSeries, "bunkerReportDiv", 0, bunkerMax);

    this.indexChanges = this.findLatestByIndex(prices);
    const providers = this.indexChanges.map((i) => i.provider);
    this.toolbarComponent.providers = providers;
    this.toolbarComponent.dateRange = this.dateRange;
    this.indexChanges.unshift(this.lastFederalReserveData(federalReserveData));
    this.indexForCompare = this.indexChanges
      .flatMap((i) => i.data)
      .filter((i) =>
        i.information.map((inf) => inf.product).includes("Propane" || "Butane")
      );
    const filstIndex = this.indexForCompare.find((i) => i.index === "PG");
    const secondIndex = this.indexForCompare.find((i) => i.index === "CP");
    this.firstIndex =
      filstIndex !== undefined ? filstIndex : this.indexForCompare[0];
    this.secondIndex =
      secondIndex !== undefined ? secondIndex : this.indexForCompare[1];
    this.compareTwoIndex();

    const aramco = prices.filter((p) => p.provider === "Aramco");
    const baltic = prices.filter((p) => p.provider === "Baltic");
    const blpg1 = baltic.filter((b) => b.productId === "BLPG1");

    const aramcoByProduct = this.yoyGroupByProduct(aramco);
    const balticByProduct = this.yoyGroupByProduct(blpg1);
    const balticProduct = this.group(
      balticByProduct.filter((p) => p.productId === "BLPG1"),
      "line"
    );
    const plotPerProduct = this.group(
      aramcoByProduct.filter((p) => p.productId === "Propane"),
      "line"
    );
    const yoyProduct = this.group(
      aramcoByProduct.filter((p) => p.productId === "Butane"),
      "line"
    );
    const barProduct = this.group(
      balticByProduct.filter((p) => p.productId === "BLPG1"),
      "column"
    );
    this.yoyPlotPerProduct(
      yoyProduct,
      "yoyCPButaneReportDiv",
      "CP Butane",
      0,
      1000
    );
    this.yoyPlotPerProduct(
      balticProduct,
      "yoyBalticReportDiv",
      "BLPG1",
      0,
      100
    );
    this.yoyBarPlotPerProduct(
      barProduct,
      "yoyColumnBalticReportDiv",
      "BLPG1",
      0,
      100
    );
    this.yoyPlotPerProduct(
      plotPerProduct,
      "yoyCPPropaneReportDiv",
      "CP Propane",
      0,
      1000
    );
  }

  importantIndexes(index: string) {
    return (
      index === "CP" ||
      index === "Argus Middle East Index" ||
      index === "PG" ||
      index === "BLPG" ||
      index === "Bunker Price" ||
      index === "Europe Brent Spot Price FOB, Daily" ||
      index === "Demolition-Bangladesh" ||
      index === "Brent" ||
      index === "WTI" ||
      index === "Fujairah" ||
      index === "VLGC"
    );
  }

  findMax(prices: PriceIndex[], productId: string) {
    return Math.max(
      ...prices.filter((p) => p.productId === productId).map((p) => p.price)
    );
  }

  findSofrMax(rates: FederalReserveBankData[]) {
    const sofrMax = Math.max(
      ...rates.filter((p) => p.indexName === "SOFR").map((p) => p.percentRate)
    );
    const sofraiMax = Math.max(
      ...rates
        .filter((p) => p.indexName === "SOFRAI")
        .map((p) => Math.max(p.average180day, p.average30day, p.average90day))
    );

    return Math.max(sofrMax, sofraiMax);
  }

  findSofrMin(rates: FederalReserveBankData[]) {
    const sofrMax = Math.min(
      ...rates.filter((p) => p.indexName === "SOFR").map((p) => p.percentRate)
    );
    const sofraiMax = Math.min(
      ...rates
        .filter((p) => p.indexName === "SOFRAI")
        .map((p) => Math.min(p.average180day, p.average30day, p.average90day))
    );

    return Math.min(sofrMax, sofraiMax);
  }

  setDateArray(startTimestamp: number, endTimestamp: number) {
    const startDate = moment(startTimestamp);
    const endDate = moment(endTimestamp);
    return Array.from(new Array(endDate.diff(startDate, "days")), () => ({
      date: startDate.add(1, "days").format("DD-MM-YYYY"),
      timestamp: startDate.unix() * 1000,
    }));
  }

  compareTwoIndex() {
    const butane1 = this.firstIndex.information.find(
      (i) => i.product === "Butane"
    ).price.price;
    const proprane1 = this.firstIndex.information.find(
      (i) => i.product === "Propane"
    ).price.price;
    const butane2 = this.secondIndex.information.find(
      (i) => i.product === "Butane"
    ).price.price;
    const propane2 = this.secondIndex.information.find(
      (i) => i.product === "Propane"
    ).price.price;
    this.diff = {
      propaneDiff: proprane1 - propane2,
      propanePercentage: (proprane1 - propane2) / propane2,
      butaneDiff: butane1 - butane2,
      butanePercentage: (butane1 - butane2) / butane2,
      fiftyFiftyMix: Math.round(
        0.5 * (proprane1 - propane2 + butane1 - butane2)
      ),
      thirtySeventyMix: Math.round(
        0.3 * (butane1 - butane2) + 0.7 * (proprane1 - propane2)
      ),
    };
  }

  groupByProduct(data: PriceIndex[]) {
    return _.chain(data)
      .groupBy((i) => i.productId)
      .map((v, k) => ({
        productId: k,
        series: _.chain(v)
          .groupBy((i) => i.index)
          .map((v1, k1) => {
            const v2 = this.setMissingDataToNull(v1, this.dateArray);
            const i: [number, number][] = v2.map((j) => [j.timestamp, j.price]);
            const visiblity = this.importantIndexes(k1);
            return {
              name: k1,
              data: i,
              visible: visiblity,
              type: "line",
            };
          })
          .value(),
      }))
      .value();
  }

  findLatestByIndex(data: PriceIndex[]) {
    const result = _.chain(data)
      .groupBy((i) => `${i.provider}§${i.index}`)
      .map((v, k) => {
        const productsPrices = _.chain(v)
          .groupBy((i) => i.productId)
          .map((v1, k1) => {
            const s = v1.sort((a, b) => a.timestamp - b.timestamp);
            const startOfMonth = moment(v1[v1.length - 1].timestamp)
              .startOf("month")
              .toDate();
            const s1 = v1
              .filter((t) => t.timestamp > startOfMonth.getTime())
              .sort((a, b) => a.timestamp - b.timestamp);
            const diffOffirst =
              s1.length > 0
                ? Math.round((s1[s1.length - 1].price - s1[0].price) * 100) /
                  100
                : null;
            const different =
              v1.length > 1
                ? Math.round(
                    (s[v1.length - 1].price - s[v1.length - 2].price) * 100
                  ) / 100
                : null;
            return {
              product: k1,
              diff: different,
              diffFromFirst: diffOffirst,
              price: s[v1.length - 1],
            };
          })
          .value();
        const keys: string[] = k.split("§");
        return {
          provider: keys[0],
          index: keys[1],
          information: productsPrices,
        };
      })
      .value();
    return _.chain(result)
      .groupBy((d) => d.provider)
      .map((v, k) => {
        const grouped = v.map((i) => {
          const ind = this.importantIndexes(i.index) ? "show" : "";
          const propanePrice = i.information.find(
            (inf) => inf.product === "Propane"
          );

          const butanePrice = i.information.find(
            (inf) => inf.product === "Butane"
          );
          const fiftyFiftyMix =
            propanePrice !== undefined && butanePrice !== undefined
              ? 0.5 * (propanePrice.price.price + butanePrice.price.price)
              : null;
          const thirtySeventyMix =
            propanePrice !== undefined && butanePrice !== undefined
              ? 0.7 * propanePrice.price.price + 0.3 * butanePrice.price.price
              : null;
          const updatedColor =
            i.information.find(
              (s) =>
                s.price.timestamp >
                moment().add(-1, "d").startOf("day").toDate().getTime()
            ) !== undefined
              ? "green"
              : i.information.find(
                  (s) =>
                    s.price.timestamp >
                    moment().add(-1, "d").startOf("day").toDate().getTime()
                ) === undefined &&
                i.information.find(
                  (s) =>
                    s.price.timestamp >
                    moment().startOf("month").toDate().getTime()
                ) !== undefined
              ? "#ffa500"
              : "red";
          if (thirtySeventyMix !== null) {
            return {
              index: i.index,
              expand: ind,
              information: i.information,
              fiftyFiftyMix: Math.round(fiftyFiftyMix),
              thirtySeventyMix: Math.round(thirtySeventyMix),
              upToDateColor: updatedColor,
            };
          } else {
            return {
              index: i.index,
              expand: ind,
              information: i.information,
              upToDateColor: updatedColor,
            };
          }
        });
        return {
          id: k.replace(/\s+/g, ""),
          provider: k,
          data: grouped.sort((a, b) => ("" + b.expand).localeCompare(a.expand)),
        };
      })
      .value();
  }

  choosedProvider(provider: string) {
    return provider === "go-shipping";
  }

  setMissingDataToNull(groupByProduct: PriceIndex[], dateArray: any[]) {
    const groubByProductHash = groupByProduct.reduce((hashMap, groupped) => {
      hashMap[moment(groupped.timestamp).format("DD-MM-YYYY")] = groupped;
      return hashMap;
    }, {});
    return dateArray
      .map((date) => {
        if (
          groubByProductHash[date.date] === undefined &&
          !DateTimeHelpers.isWeekend(date.timestamp) &&
          !this.choosedProvider(groupByProduct[0].provider)
        ) {
          return {
            provider: groupByProduct[0].provider,
            timestamp: date.timestamp,
            index: groupByProduct[0].index,
            productId: groupByProduct[0].productId,
            price: null,
            isDeleted: false,
          };
        } else {
          return groubByProductHash[date.date];
        }
      })
      .filter((d) => d !== undefined);
  }

  plotPerProduct(data: any, divId: string, yMin = null, yMax = null) {
    if (!data && data.series && data.series.length === 0) {
      return;
    }
    Highcharts.chart(divId, {
      chart: {
        type: "line",
      },
      title: {
        text: `Daily ${data.productId}`,
      },
      yAxis: {
        title: {
          text: "index",
        },
        labels: {
          formatter() {
            return "$ " + this.value;
          },
        },
        min: yMin,
        max: yMax,
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}',
        split: true,
        xDateFormat: "%Y-%m-%d",
      },
      xAxis: {
        type: "datetime",
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      series: data.series,
    });
  }

  plotBunkerPrices(data: any, divId: string, yMin = null, yMax = null) {
    if (!data && data.length === 0) {
      return;
    }

    const chartSeries = data.map((i) => ({
      name: i.productId,
      data: i.series[0].data,
      type: "line",
    }));

    Highcharts.chart(divId, {
      chart: {
        type: "line",
      },
      title: {
        text: "Fujairah Bunker Index",
      },
      yAxis: {
        title: {
          text: "index",
        },
        labels: {
          formatter() {
            return "$ " + this.value;
          },
        },
        min: yMin,
        max: yMax,
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}',
        split: true,
        xDateFormat: "%Y-%m-%d",
      },
      xAxis: {
        type: "datetime",
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      series: chartSeries,
    });
  }

  group(data: PriceIndex[], typeChart: string) {
    return _.chain(data)
      .groupBy((i) => new Date(i.timestamp).getFullYear())
      .map((i, key) => ({
        name: `${key}`,
        data: i.map((j) => [moment(j.timestamp).format("MMM"), j.price]),
        type: typeChart,
      }))
      .value();
  }

  yoyPlotPerProduct(
    chartSeries: any,
    divId: string,
    title: string,
    yMin: number,
    yMax: number
  ) {
    Highcharts.chart(divId, {
      chart: {
        type: "line",
      },
      title: {
        text: `Year on Year ${title}`,
      },
      yAxis: {
        title: {
          text: "index",
        },
        labels: {
          formatter() {
            return "$ " + this.value;
          },
        },
        min: yMin,
        max: yMax,
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}',
        split: true,
        xDateFormat: "%Y-%m-%d",
      },
      xAxis: {
        type: "category",
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      series: chartSeries,
    });
  }

  yoyBarPlotPerProduct(
    chartSeries: any,
    divId: string,
    title: string,
    yMin,
    yMax
  ) {
    Highcharts.chart(divId, {
      chart: {
        type: "column",
      },
      title: {
        text: `Year on Year ${title}`,
      },
      yAxis: {
        title: {
          text: "index",
        },
        labels: {
          formatter() {
            return "$ " + this.value;
          },
        },
        min: yMin,
        max: yMax,
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}',
        split: true,
        xDateFormat: "%Y-%m-%d",
      },
      xAxis: {
        type: "category",
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      series: chartSeries,
    });
  }

  private yoyGroupByProduct(data: PriceIndex[]): PriceIndex[] {
    return _.chain(data)
      .map((d) => ({
        provider: d.provider,
        index: d.index,
        timestamp: moment(d.timestamp).startOf("month").unix() * 1000,
        productId: d.productId,
        price: d.price,
        isDeleted: d.isDeleted,
      }))
      .groupBy((d) => `${d.productId}§${d.timestamp}`)
      .map((items) => {
        const d = items[0];
        return {
          provider: d.provider,
          index: d.index,
          timestamp: d.timestamp,
          productId: d.productId,
          price: _.round(
            _.meanBy(items, (i) => i.price),
            2
          ),
          isDeleted: d.isDeleted,
        };
      })
      .value();
  }

  groupSOFRData(data: FederalReserveBankData[]) {
    const sofrai = _.chain(data)
      .groupBy((i) => i.indexName)
      .value();
    const average30day: [number, number][] = sofrai["SOFRAI"]
      .sort(
        (s1, s2) =>
          new Date(s1.effectiveDate).getTime() -
          new Date(s2.effectiveDate).getTime()
      )
      .map((j) => [new Date(j.effectiveDate).getTime(), j.average30day]);
    const average90day: [number, number][] = sofrai["SOFRAI"]
      .sort(
        (s1, s2) =>
          new Date(s1.effectiveDate).getTime() -
          new Date(s2.effectiveDate).getTime()
      )
      .map((j) => [new Date(j.effectiveDate).getTime(), j.average90day]);
    const average180day: [number, number][] = sofrai["SOFRAI"]
      .sort(
        (s1, s2) =>
          new Date(s1.effectiveDate).getTime() -
          new Date(s2.effectiveDate).getTime()
      )
      .map((j) => [new Date(j.effectiveDate).getTime(), j.average180day]);

    const sofr: [number, number][] = sofrai["SOFR"]
      .sort(
        (s1, s2) =>
          new Date(s1.effectiveDate).getTime() -
          new Date(s2.effectiveDate).getTime()
      )
      .map((j) => [new Date(j.effectiveDate).getTime(), j.percentRate]);

    const series = [
      {
        name: "SOFR",
        data: sofr,
        visible: true,
        type: "line",
      },
      {
        name: "30 dayes",
        data: average30day,
        visible: true,
        type: "line",
      },
      {
        name: "90 dayes",
        data: average90day,
        visible: true,
        type: "line",
      },
      {
        name: "180 dayes",
        data: average180day,
        visible: true,
        type: "line",
      },
    ];
    return {
      seriesName: "SOFR",
      series,
    };
  }

  findLatestFederalReserveByIndex(data: any[], index: string) {
    const filteredData = data.sort(
      (a, b) =>
        new Date(a.effectiveDate).getTime() -
        new Date(b.effectiveDate).getTime()
    );
    const startOfMonth = moment(
      filteredData[filteredData.length - 1].effectiveDate
    )
      .startOf("month")
      .toDate();
    const latest = filteredData[filteredData.length - 1];
    const s1 = filteredData
      .filter(
        (t) => new Date(t.effectiveDate).getTime() > startOfMonth.getTime()
      )
      .sort(
        (a, b) =>
          new Date(a.effectiveDate).getTime() -
          new Date(b.effectiveDate).getTime()
      );
    const diffFromFirst =
      s1.length > 0
        ? Math.round((s1[s1.length - 1].price - s1[0].price) * 100) / 100
        : null;
    const diff =
      filteredData.length > 1
        ? Math.round(
            (latest.price - filteredData[filteredData.length - 2].price) * 100
          ) / 100
        : null;
    const price: PriceIndex = {
      timestamp: new Date(latest.effectiveDate).getTime(),
      price: latest.price,
      provider: "Federal Reserve Bank Of New York",
      productId: index,
      isDeleted: false,
      index,
    };

    return {
      product: index,
      diff,
      diffFromFirst,
      price,
    };
  }

  uptodateColor(data) {
    return data.find(
      (s) =>
        s.price.timestamp >
        moment().add(-1, "d").startOf("day").toDate().getTime()
    ) !== undefined
      ? "green"
      : data.find(
          (s) =>
            s.price.timestamp >
            moment().add(-1, "d").startOf("day").toDate().getTime()
        ) === undefined &&
        data.find(
          (s) =>
            s.price.timestamp > moment().startOf("month").toDate().getTime()
        ) !== undefined
      ? "#ffa500"
      : "red";
  }

  lastFederalReserveData(data: FederalReserveBankData[]) {
    const grouppedData = _.chain(data)
      .groupBy((i) => i.indexName)
      .value();

    const sofr = grouppedData["SOFR"].map((d) => ({
      effectiveDate: d.effectiveDate,
      indexName: d.indexName,
      price: d.percentRate ? d.percentRate : null,
    }));

    const average30day = grouppedData["SOFRAI"].map((d) => ({
      effectiveDate: d.effectiveDate,
      indexName: d.indexName,
      price: d.average30day ? d.average30day : null,
    }));

    const average90day = grouppedData["SOFRAI"].map((d) => ({
      effectiveDate: d.effectiveDate,
      indexName: d.indexName,
      price: d.average90day ? d.average90day : null,
    }));

    const average180day = grouppedData["SOFRAI"].map((d) => ({
      effectiveDate: d.effectiveDate,
      indexName: d.indexName,
      price: d.average180day ? d.average180day : null,
    }));

    const sofrData = this.findLatestFederalReserveByIndex(sofr, "SOFR");
    const upToDateSOFRColor = this.uptodateColor([sofrData]);
    const latestSOFR = {
      expand: "show",
      index: "SOFR",
      upToDateColor: upToDateSOFRColor,
      information: [sofrData],
    };

    const thirtyDays = this.findLatestFederalReserveByIndex(
      average30day,
      "Average 30 days"
    );
    const ninteyDays = this.findLatestFederalReserveByIndex(
      average90day,
      "Average 90 days"
    );
    const sixMonth = this.findLatestFederalReserveByIndex(
      average180day,
      "Average 180 days"
    );

    const information = [thirtyDays, ninteyDays, sixMonth];
    const upToDateThirtyDaysColor = this.uptodateColor(information);
    const latestSOFRAI = {
      expand: "",
      index: "SOFRAI",
      upToDateColor: upToDateThirtyDaysColor,
      information: information,
    };

    return {
      id: "federalReserve",
      provider: "Federal Reserve Bank Of New York",
      data: [latestSOFR, latestSOFRAI],
    };
  }

  plotFederalReserveData(data: any, divId: string, yMin = null, yMax = null) {
    if (!data && data.series && data.series.length === 0) {
      return;
    }
    Highcharts.chart(divId, {
      chart: {
        type: "line",
      },
      title: {
        text: `Daily ${data.seriesName}`,
      },
      yAxis: {
        title: {
          text: "index",
        },
        labels: {
          formatter() {
            return "$ " + this.value;
          },
        },
        min: yMin,
        max: yMax,
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}',
        split: true,
        xDateFormat: "%Y-%m-%d",
      },
      xAxis: {
        type: "datetime",
        // labels: {
        //   formatter: function() {
        //     console.log(this.value)
        //     return Highcharts.dateFormat('%Y/%m/%d', Number(this.value));
        //   }
        // }
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      series: data.series,
    });
  }
}

<div class="row">
  <div class="card-columns col-md-12" *ngIf="indexChanges">
    <div class="card shadow-sm">
      <div class="card-header card-title">Difference</div>
      <div class="card-body">
        <div>
          <div>
            <h6>
              Butane: {{ diff.butaneDiff }}
              <small
                class="text-green"
                [ngClass]="{
                  'description-percentage': true,
                  'text-green': diff.butaneDiff > 0,
                  'text-yellow': diff.butaneDiff === 0,
                  'text-red': diff.butaneDiff < 0
                }"
              >
                {{ diff.butanePercentage | percent }}
              </small>
            </h6>
            <h6>
              Propane: {{ diff.propaneDiff }}
              <small
                class="text-green"
                [ngClass]="{
                  'description-percentage': true,
                  'text-green': diff.propaneDiff > 0,
                  'text-yellow': diff.propaneDiff === 0,
                  'text-red': diff.propaneDiff < 0
                }"
              >
                {{ diff.propanePercentage | percent }}
              </small>
            </h6>
            <h6>50-50: {{ diff.fiftyFiftyMix }}</h6>
            <h6>70-30: {{ diff.thirtySeventyMix }}</h6>
          </div>
          <div class="form-group">
            <select
              class="form-control mb-3"
              id="firstIndex"
              name="firstIndex"
              [(ngModel)]="firstIndex"
              (change)="compareTwoIndex()"
              required
            >
              <option *ngFor="let index of indexForCompare" [ngValue]="index">
                {{ index.index }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <select
              class="form-control"
              id="secondIndex"
              name="secondIndex"
              [(ngModel)]="secondIndex"
              (change)="compareTwoIndex()"
              required
            >
              <option *ngFor="let index of indexForCompare" [ngValue]="index">
                {{ index.index }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="card shadow-sm" *ngFor="let item of indexChanges">
      <div class="card-header card-title">
        {{ item.provider }}
      </div>
      <div class="card-body">
        <div *ngFor="let index1 of item.data; let in = index">
          <p>
            <a
              data-bs-toggle="collapse"
              href="#{{ item.id }}{{ in }}"
              role="button"
              aria-expanded="true"
              aria-controls="multiCollapseExample1"
              [ngStyle]="{ color: index1.upToDateColor }"
              >{{ index1.index }}
            </a>
          </p>
          <div class="collapse {{ index1.expand }}" id="{{ item.id }}{{ in }}">
            <ng-container *ngFor="let product of index1.information">
              <div>
                <h6>
                  {{ product.product }}: {{ product.price?.price }}
                  <small
                    class="text-green"
                    [ngClass]="{
                      'description-percentage': true,
                      'text-green': product.diff > 0,
                      'text-yellow': product.diff === 0,
                      'text-red': product.diff < 0
                    }"
                  >
                    {{ product.diff }}&#36;
                  </small>
                  <small
                    class="text-green"
                    [ngClass]="{
                      'description-percentage': true,
                      'text-green': product.diffFromFirst > 0,
                      'text-yellow': product.diffFromFirst === 0,
                      'text-red': product.diffFromFirst < 0
                    }"
                  >
                    {{ product.diffFromFirst }}&#36;
                  </small>
                  <small class="text-muted">{{
                    product.price?.timestamp | date : "MMM d yyyy"
                  }}</small>
                </h6>
              </div>
            </ng-container>
            <div *ngIf="index1.fiftyFiftyMix">
              <ng-container>
                <h6>70-30: {{ index1.thirtySeventyMix }}</h6>
                <h6>50-50: {{ index1.fiftyFiftyMix }}</h6>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-4">
    <div class="card shadow-sm">
      <div id="butaneReportDiv"></div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card shadow-sm">
      <div id="propaneReportDiv"></div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card shadow-sm">
      <div id="crudOilReportDiv"></div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card shadow-sm">
      <div id="bunkerReportDiv"></div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card shadow-sm">
      <div id="balticReportDiv"></div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card shadow-sm">
      <div id="demolitionReportDiv"></div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card shadow-sm">
      <div id="yoyBalticReportDiv"></div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card shadow-sm">
      <div id="yoyColumnBalticReportDiv"></div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card shadow-sm">
      <div id="yoyCPButaneReportDiv"></div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card shadow-sm">
      <div id="yoyCPPropaneReportDiv"></div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="card shadow-sm">
      <div id="federalReserveDiv"></div>
    </div>
  </div>
</div>

<section class="content">
  <div class="row">
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h3>{{allCrew?.length}}</h3>
          <p>Pool size</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h3>{{onBoards?.length}}</h3>
          <p>Onboard</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h3>{{numberOfOnSignersInLast30Days}}</h3>
          <p>On signers in the last 30 days</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h3>{{numberOfOffSignersInLast30Days}}</h3>
          <p>Off signers in the last 30 days</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <h2>Status</h2>
      <table id="crewsPerVessel"
             class="table table-sm table-striped table-bordered overflow-x">
        <thead>
        <tr>
          <th>Vessel</th>
          <th>Crew count</th>
          <th>Master</th>
          <th>Chief officer</th>
          <th>Chief Engineer</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let crew of onboardPerVessel">
          <td>{{crew.vesselName}}</td>
          <td>{{crew.crewCount}}</td>
          <td>
            <ng-container *ngFor="let m of crew.masters">
              <span class="m-2" [ngClass]="getCountryCode(m.organisationId)"></span>
              <a [routerLink]="['/vessels/crew-change/crew/profile/', m.id]">{{m.name}}</a>
            </ng-container>
          </td>
          <td>
            <ng-container *ngFor="let m of crew.chiefOfficers">
              <span class="m-2" [ngClass]="getCountryCode(m.organisationId)"></span>
              <a [routerLink]="['/vessels/crew-change/crew/profile/', m.id]">{{m.name}}</a>
            </ng-container>
          </td>
          <td>
            <ng-container *ngFor="let m of crew.chiefEngineers">
              <span class="m-2" [ngClass]="getCountryCode(m.organisationId)"></span>
              <a [routerLink]="['/vessels/crew-change/crew/profile/', m.id]">{{m.name}}</a>
            </ng-container>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row">
    <h2>Crew</h2>
    <app-crew-changes [crews]="allCrew"
                      [organisationsMap]="organisationsMap"></app-crew-changes>
  </div>
</section>

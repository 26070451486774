import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Crew, CrewChange, CrewChangeServices } from '../crew-change.service';
import {Organisation, OrganisationService} from 'src/app/organisation/organisation.service';

@Component({
  selector: 'app-crew-changes',
  templateUrl: './crew-changes.component.html'
})
export class CrewChangeListComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title: EventEmitter<string> = new EventEmitter();

  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();

  _crews: Crew[];
  _organisationsMap: Map<string, Organisation> = new Map();
  getCountryCode = this._organisationService.getCountryCode;


  @Input() set crews(crews: Crew[]) {
    if (crews) {
      this._crews= crews;
      this._crews.forEach(crew => {
        crew.crewChanges.sort((a, b) => Math.max(...b.item.map(i => i.time)) - Math.max(...a.item.map(i => i.time)));
      });
    }
  }

  @Input() set organisationsMap(organisationsMap: Map<string, Organisation>) {
    if (organisationsMap && this._crews) {
      this._organisationsMap = organisationsMap;
      this.initialiseDataTables();
    }
  }

  constructor(private _crewChangeService: CrewChangeServices, private _organisationService: OrganisationService) { }

  ngOnInit(): void {
    this.dtOptions = DataTablesService.getOptionsWithNoColumnsEn([]);
    this.title.emit('Crew Change List');
  }

  getOrganisationNameById(id: string) {
    if (this._organisationsMap && id) {
      return this._organisationsMap.get(id).nameTranslations['en'];
    }
    return 'Unknown';
  }

  onboardTimeMonthsDays(crewChange: CrewChange) {
    return this._crewChangeService.onboardTimeMonthsDays(crewChange);
  }

  travelTimeDays(crewChange: CrewChange) {
    return this._crewChangeService.travelTimeDays(crewChange);
  }

  initialiseDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  planTime(crewChange: CrewChange, selectedPlan: string) {
    if(this._organisationsMap) {
      return this._crewChangeService.planTime(crewChange, selectedPlan, this._organisationsMap);
    }
  }

  planPlace(crewChange: CrewChange, selectedPlan: string) {
    if(this._organisationsMap) {
      return this._crewChangeService.planPlace(crewChange, selectedPlan, this._organisationsMap);
    }
  }

  getStatus(crew: Crew) {
    if(crew) {
      return this._crewChangeService.getCrewStatus(crew);
    }
  }

  numberOfCrewChanges(crewChanges: CrewChange[]) {
    return crewChanges.filter(cr => !cr.isDeleted).length;
  }

  getLastCrewChange(crewChanges: CrewChange[]) {
    if(crewChanges.length > 0) {
      return this._crewChangeService.getLastCrewChange(crewChanges);
    } else {
      return null;
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-pdf-viewer-pop-up',
  templateUrl: './pdf-viewer-pop-up.component.html',
})
export class PdfViewerPopUpComponent implements OnInit {
  previewFileId: string;
  filePreviewDialogModal: any | undefined;
  filePreviewTitle: string;
  _show: boolean;

  @Input() set show(s: boolean) {
    this.filePreviewDialogModal = $('#filePreviewModal').modal();
    if(s) {
      this.filePreviewDialogModal.show();
    } else {
      this.filePreviewDialogModal.hide();
    }
    this._show = s;
  }

  @Output() showChange =  new EventEmitter<boolean>();

  @Input() set fileId(f: string) {
    this.previewFileId = f;
  }

  @Input() set title(t: string) {
    this.filePreviewTitle = t;
  }

  ngOnInit(): void {
    this.filePreviewDialogModal = $('#filePreviewModal').modal();
  }

  closeFilePreview() {
    this.filePreviewDialogModal.hide();
    this.previewFileId = '';
    this._show = false;
    this.showChange.emit(this._show);
  }
}

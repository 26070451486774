<div class="input-group">
  <app-date-range
    class="ms-1"
    [singleDateSelector]="false"
    [(dateRange)]="dateRange"
  >
  </app-date-range>
  <select
    class="form-control ms-1"
    id="selectedProvider"
    name="selectedProvider"
    [(ngModel)]="selectedProvider"
    required
  >
    <option *ngFor="let provider of providers" [ngValue]="provider">
      {{ provider }}
    </option>
  </select>

  <div class="btn-group ms-1" aria-label="Button group with nested dropdown">
    <button
      class="btn text-muted dropdown-toggle"
      type="button"
      id="addButtonGroup"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      Actions
    </button>
    <ul class="dropdown-menu" aria-labelledby="addButtonGroup">
      <li>
        <a
          class="btn text-muted ms-1"
          data-bs-toggle="tooltip"
          title="Subscribe"
          (click)="subscribe()"
        >
          <i class="fa fa-bell me-1"> </i>
          Subscribe
        </a>
      </li>
      <li>
        <a
          class="btn text-muted ms-1"
          data-bs-toggle="tooltip"
          title="List of PDF files"
          (click)="marketFiles()"
        >
          <i class="fas fa-file-export me-1"> </i>
          Download
        </a>
      </li>
      <li>
        <a
          class="btn text-muted ms-1"
          data-bs-toggle="tooltip"
          title="List of prices"
          (click)="pricingList()"
        >
          <i class="fa fa-list-alt me-1"> </i>
          List
        </a>
      </li>
      <li>
        <a
          class="btn text-muted ms-1"
          data-bs-toggle="tooltip"
          title="Refresh"
          (click)="pricingPlotAll()"
        >
          <i class="fa fa-refresh me-1"> </i>
          Refresh
        </a>
      </li>
    </ul>
  </div>
</div>

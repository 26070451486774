import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { PgToolbarComponent } from '../../../../shared/components/title-bar/title-bar.component';
import { FleetPersonnelToolbarComponent } from './fleet-personnel-toolbar/fleet-personnel-toolbar.component';
import { Crew, CrewChange, CrewChangeServices } from '../crew-change.service';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-fleet-management',
  templateUrl: './fleet-personnel-management.component.html'
})
export class FleetPersonnelManagementComponent implements OnInit, PgToolbarComponent {
  @ViewChild(DataTableDirective, {static: true})
  chiefsDtElement: DataTableDirective;

  title: EventEmitter<string> = new EventEmitter();
  toolbarComponentType = FleetPersonnelToolbarComponent;
  toolbarComponent: FleetPersonnelToolbarComponent;

  numberOfOffSignersInLast30Days: number = null;
  numberOfOnSignersInLast30Days: number = null;
  crewChanges: CrewChange[];
  onBoards: [Crew, CrewChange][];
  onboardPerVessel: any[];
  allCrew: Crew[];
  organisations: Organisation[];
  organisationsMap: Map<string, Organisation>;
  getCountryCode = this._organisationService.getCountryCode;

  constructor(
    private _crewChangeService: CrewChangeServices,
    private _organisationService: OrganisationService
    ) { }

  ngOnInit(): void {
    this.title.emit('Fleet personnel');
    this.fillData();
  }

  getNumberOfOffSignersInLast30Days() {
    const period = moment().subtract(30, 'days').unix() * 1000;
    return this.crewChanges.filter(c => {
      if (c.item && c.item.length > 0) {
        const lastAction  = c.item.sort((a, b) => b.time - a.time)[0];
        return lastAction.plan === 'Outgoing Flight' && lastAction.time > period;
      }
      return false;
    }).length;
  }

  getNumberOfOnSignersInLast30Days() {
    const period = moment().subtract(30, 'days').unix() * 1000;
    return this.crewChanges.filter(c => {
      if (c.item && c.item.length > 0) {
        const lastAction = c.item.sort((a, b) => b.time - a.time)[0];
        return lastAction.plan === 'On Board' && lastAction.time > period;
      }
      return false;
    }).length;

  }

  fillData() {
    forkJoin([
      this._crewChangeService.getOnboardCrew(),
      this._organisationService.get(),
      this._organisationService.getOrganisationIdToOrganisationMap(),
    ]).subscribe((value) => {
        this.allCrew = value[0].map(i => i[0]);
        this.crewChanges = this.allCrew.flatMap(c => c.crewChanges);
        this.organisations = value[1];
        this.organisationsMap = value[2];
        this.onBoards = value[0].filter(r => r[1] !== null && r[1] !== undefined);
        const formatCrewRanks = (items: [Crew, CrewChange][], rank: string) =>
          items.filter(i => i[1].rank === rank)
            .map(i => ({
              id: i[0].id,
              name: [i[0].firstName, i[0].middleName, i[0].lastName].join(' '),
              nationality: this.organisationsMap.get(i[0].nationality).nameTranslations['en'],
              organisationId: i[0].nationality
            }));
        const g = _.groupBy(this.onBoards, (item) => item[1].vesselName);
        this.onboardPerVessel = _(g)
          .map((items, key) =>({
              vesselName: key,
              crewCount: items.length,
              masters: formatCrewRanks(items, 'Master'),
              chiefOfficers: formatCrewRanks(items, 'Chief Officer'),
              chiefEngineers: formatCrewRanks(items, 'Chief Engineer'),
            })
          ).orderBy((i => i.vesselName)).value();
        this.numberOfOffSignersInLast30Days = this.getNumberOfOffSignersInLast30Days();
        this.numberOfOnSignersInLast30Days = this.getNumberOfOnSignersInLast30Days();
      }
    );
  }
}
